<template>
  <v-container fluid>
    <ssl-table :data="data" :loading="loading" @deleteSSL="deleteSSL" />
  </v-container>
</template>

<script>
import { getSsl, deleteSSL } from "@/api/system/ssl";
import sslTable from "@/views/system/ssl/table";

import { Errors } from "@/utils/error";

export default {
  components: {
    sslTable,
  },
  data() {
    return {
      data: {
        allowedparts: {},
      },
      loading: true,
      deleteMenu: false,
    };
  },
  computed: {
    changeInAddForm: {
      get() {
        return this.$store.state.settings.changeInAddForm;
      },
      set(val) {
        this.$store.dispatch("settings/changeSetting", {
          key: "changeInAddForm",
          value: val,
        });
      },
    },
  },
  watch: {
    changeInAddForm: {
      immediate: true,
      deep: true,
      handler(newValue) {
        if (newValue && newValue === "systemSsl") {
          this.loadData(this.page, this.size);
          this.$toast.open({
            message: "The page will be refreshed to complete the setup.",
            type: "success",
            position: "top-right",
            duration: 2000,
          });
          setTimeout(() => {
            window.location =
              "http://" + this.data.ssl_domain + "/system/show/ssl";
          }, 2000);
        }
        this.changeInAddForm = "";
      },
    },
  },
  created() {
    this.loadData();
  },
  methods: {
    loadData() {
      this.loading = true;
      getSsl()
        .then((response) => {
          this.loading = false;
          this.data = response.data;
        })
        .catch((e) => {
          Errors.show(e);
          this.loading = false;
        });
    },
    deleteSSL() {
      this.loading = true;
      const domain = this.data.ssl_domain;
      const data = this.data;
      this.data = {};
      deleteSSL()
        .then(() => {
          this.$toast.open({
            message: "SSL was successfully deleted!",
            type: "success",
            position: "top-right",
            duration: 2000,
          });
          this.$toast.open({
            message: "The page will be refreshed to complete the setup.",
            type: "success",
            position: "top-right",
            duration: 2000,
          });
          setTimeout(() => {
            window.location = "http://" + domain + "/system/show/ssl";
          }, 2000);
          this.loadData();
          this.loading = false;
        })
        .catch((e) => {
          Errors.show(e);
          this.$nextTick(() => {
            this.data = data;
            this.loading = false;
          });
        });
    },
  },
};
</script>
