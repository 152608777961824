<template>
  <v-row>
    <v-col cols="12" class="py-1 px-0">
      <v-simple-table
        hide-default-footer
        :loading="loading"
        loading-text="Loading data from API...please wait"
        class="synapsa-table version"
        :class="{'progress-linear': loading}"
      >
      <template v-if="loading">
        <v-progress-linear
        class="mt-0"
        indeterminate
        />
        <tbody>
            <tr>
              <td class="loading-text">Loading data from API...please wait</td>
            </tr>
          </tbody>
        </template>
        <template v-else>
          <tbody>
            <tr>
              <td style="border-radius: 15px 0 0 0">
                {{ $t("system.ssl.status") }}:
              </td>
              <td style="border-radius: 0 15px 0 0">
                {{ data.ssl === "false" ? "Not Set" : data.ssl }}
              </td>
            </tr>
            <tr>
              <td>{{ $t("system.ssl.type") }}:</td>
              <td>
                {{ data.ssl_type }}
              </td>
            </tr>
            <tr>
              <td>{{ $t("system.ssl.domain") }}:</td>
              <td>
                {{ data.ssl_domain }}
              </td>
            </tr>
            <tr>
              <td>{{ $t("system.ssl.ownCertName") }}:</td>
              <td>
                {{ data.ssl_owncert_name }}
              </td>
            </tr>
            <tr v-if="data.ssl_type === 'own'">
              <td>{{ $t("system.ssl.validFrom") }}:</td>
              <td>{{ data.ssl_valid_from }}</td>
            </tr>
            <tr v-if="data.ssl_type === 'own'">
              <td>{{ $t("system.ssl.validTo") }}:</td>
              <td>{{ data.ssl_valid_to }}</td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-col>
    <v-col cols="12" class="px-0">
      <v-menu
        v-if="data.ssl !== 'false' && !loading"
        v-model="deleteMenu"
        :close-on-content-click="false"
        rounded
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn small class="py-4 mt-2" color="bad-2" v-on="on" v-bind="attrs">
            Delete
          </v-btn>
        </template>

        <v-card>
          <v-list width="350">
            <v-list-item>
              <v-list-item-content class="pb-0">
                <v-list-item-title
                  >Are you sure you want to delete SSL?
                </v-list-item-title>
                <v-list-item-subtitle class="text-right mt-2">
                  <v-btn
                    rounded
                    text
                    color="tertiary"
                    small
                    class="text-capitalize"
                    @click="deleteMenu = false"
                  >
                    Cancel
                  </v-btn>
                  <v-btn
                    rounded
                    text
                    color="bad-2"
                    small
                    class="text-capitalize"
                    @click="deleteSSL()"
                  >
                    Delete
                  </v-btn>
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card>
      </v-menu>
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: {
    loading: {
      type: Boolean,
      default: function () {
        return true;
      },
    },
    data: {
      type: Object,
      default: function () {
        return [];
      },
    },
  },
  data() {
    return {
      deleteMenu: false,
    };
  },
  computed: {},
  methods: {
    deleteSSL() {
      this.$emit("deleteSSL");
      this.deleteMenu = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.progress-linear {
  border-top-right-radius: 0;
  border-top-left-radius: 0;
}
.system-container {
.v-data-table__wrapper {
  border-radius: 0px;
}
}
.theme--dark{
  .loading-text {
    text-align: center;
    color: rgba(255, 255, 255, 0.5)
  }
}
.theme--light {
  .loading-text{
    text-align: center;
    color: rgba(0,0,0,.38);
  }
}
</style>
